import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  AnimationSettingsModel,
  DialogComponent,
  DialogUtility,
} from "@syncfusion/ej2-react-popups";
import * as R from "ramda";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import { msalInstance } from "../../..";
//import ACLoadingPanel from '../../../components/shared/ACLoadingPanel';
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDatePickerCustom from "../../../RAFComponents/Inputs/RAFDatePickerCustom";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFFieldLabel from "../../../RAFComponents/Inputs/RAFFieldLabel";
import RAFForm, {
  Condition,
  ConditionIncludes,
  ConditionNotIncludes,
} from "../../../RAFComponents/Inputs/RAFForm";
import RAFRadioButtonList from "../../../RAFComponents/Inputs/RAFRadioButtonList";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import {
  getFormValue,
  setFormValue,
} from "../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import RAFRelatedToWithIcon from "../../../RAFComponents/Navigation/RAFRelatedToWithIcon";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  hideProgress,
  showProgress
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender, {
  hasPermission,
} from "../../../RAFComponents/helpers/PermissionHelper";
import { getURLNameByModuleName } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  CalculateDate,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { getEntityByName } from "../../../RAFMaster/helpers/RMutils";

import RAFAutoCompleteMUITagInput from "../../../RAFComponents/Inputs/RAFAutoCompleteMUITagInput";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFStartDateEndDatePicker from "../../../RAFComponents/Inputs/RAFStartDateEndDatePicker";
import RAFTaskTagInput from "../../../RAFComponents/Inputs/RAFTaskTagInput";
import RAFTextBox from "../../../RAFComponents/Inputs/RAFTextBox";

import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import {
  Constants,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
  RAFStatusNameWithColor,
  RAFTaskType,
} from "../../../constants/Common/Constants";
import {
  RAFEntityName,
  RAFModuleName,
} from "../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import { RAFEntityType } from "../../../constants/Common/RMConstants";
import { NavigateParamsProps, withRouter } from "../../../router";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { UserRow } from "../User/UserRow";
import { RepeatProcesssItems } from "./RAFRepeatProcesssDropdown";
import {
  getTaskDisplayDateAndBadge,
  getTaskDueDate,
  retrieveTaskWithoutReminders,
  saveTaskWithReminders,
} from "./TaskHelper";
import RAFRelatedLookupSelector from "./TaskInput/RAFRelatedLookupSelector";
import { TaskRow } from "./TaskRow";

interface IProps {
  complete?: (taskRow: TaskRow, taskStatus: string) => void;
  completeNextTask?: (taskRow: TaskRow) => void;
  onSave?: (taskRow?: TaskRow) => void;
  onDelete?: (taskRow?: TaskRow) => void;
  isDashboard?: boolean;
  isEditTask?: boolean;
  isCreateTask?: boolean;
  taskUID?: string;
  contextModuleName?: string;
  onFocusClicked?: (relatedTo: string, relatedUID: string) => void;
  relatedCommentsUpdated?: (isRelatedCommentsUpdated?: boolean) => void;
  isDetailsMode?: boolean;
  secondaryRelatedToUID?: string;
  secondaryRelatedTo?: string;
  secondaryRelatedToType?: string;
  relatedToUID?: string;
  relatedTo?: string;
  relatedToType?: string;
  defaultTaskType?: RAFTaskType;
  hideHeader?: boolean;
  onClose?: () => void;
  isActive?: boolean;
  question?: any;
  itemRefId?: string;
  actionsList?: TaskRow[];
  onActionAdded?: (actionsList: TaskRow[]) => void;
  questionValue?: any;
}

interface IState {
  isActive: boolean;
  taskRow: TaskRow;
  taskUID: string;
  isEditTask?: boolean;
  updatedTaskRow?: TaskRow;
  documentSummaryKey?: number;
  activitySummaryKey?: number;
  taskDetailsSectionKey?: number;
  isLoading: boolean;
  showCompleteTaskAlertDialogContent: boolean;
  showRelatedToTaskDialogContent: boolean;
  relatedBPWidgetKey?: number;
  showAddToFocusListDialog?: boolean;
  relatedToContentKey?: number;
}

function ManageAction({
  ...props
}: PropsWithChildren<IProps & RAFFormComponentProps & NavigateParamsProps>) {
  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;
  const objGuid = isNotNullAndUndefined(props.taskUID)
    ? props.taskUID
    : props.isCreateTask
      ? "create"
      : "na";

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: false,
      isLoading: false,
      isEditTask: false,
      updatedTaskRow: null,
      taskRow: null,
      taskUID: isNotNullAndUndefined(props.taskUID) ? props.taskUID : null,
      documentSummaryKey: Math.random(),
      activitySummaryKey: Math.random(),
      taskDetailsSectionKey: Math.random(),
      showAddToFocusListDialog: false,
      showCompleteTaskAlertDialogContent: false,
      showRelatedToTaskDialogContent: false,
      relatedBPWidgetKey: Math.random(),
      relatedToContentKey: Math.random(),
    }
  );

  let rafForm: FormRenderProps | null;
  let rafLookupSelectorForm: FormRenderProps | null;
  let submitButton: ButtonComponent;
  let animationSettings: AnimationSettingsModel;
  let deleteDialog: any;

  let updateTaskStatusAlertDialog: any;
  let contextModuleName: string = isNotNullAndUndefined(props.contextModuleName)
    ? props.contextModuleName
    : createInstance(TaskRow).getClassName();
  let moduleName: string = createInstance(TaskRow).getClassName();

  async function checkScrollable() {
    const div = await document.querySelector("#raf_tab_header_manage_task");
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_manage_task_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_manage_task_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  useEffect(() => {
    if (isNotNullAndUndefined(props.taskUID)) {
      refresh(props.taskUID, props.isActive);
    } else {
      refresh(null, props.isActive ? props.isCreateTask : false);
    }
  }, [props.taskUID]);
  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (isNotNullAndUndefined(submitButton)) {
        submitButton.disabled = !g.valid;
      }
    }
  };

  const onSubmitTask = (taskRow: TaskRow) => {
    let progressDiv = showProgress(`#manage_Task_outerDiv_${objGuid}`);

    saveTaskWithReminders(taskRow).then((entityId) => {
      if (isNotNullAndUndefined(entityId)) {
        let { actionsList, question, questionValue, itemRefId } = props;
        showSuccessToast("Saved successfully");
        hideProgress(progressDiv);

        // if (props.onSave) {
        //   props.onSave(value);
        // }

        if (isNotNullAndUndefined(taskRow.UID)) {
          let index = actionsList.findIndex((x) => x.UID === taskRow.UID);
          actionsList.splice(index, 1);
        } else {
          taskRow.UID = entityId;
        }
        actionsList.push({ ...taskRow });

        // let newValue = questionValue || {};
        // newValue[question.name + "_actionId"] = itemRefId;
        // (question.survey as any).setValue(question.name, newValue);

        let parentQuestion = question.data;
        let parentQuestionName = parentQuestion.name;
        let newValue = questionValue || {};
        newValue[question.name] = itemRefId;
        (question.survey as any).setValue(parentQuestionName, newValue);

        //showSuccessToast("Action saved successfully !");
        if (props.onActionAdded) props.onActionAdded(actionsList);

        onCloseDialogClick();
      } else {
        showWarningToast("Sorry unable to update the changes.");
        hideProgress(progressDiv);
      }
    });
  };

  const onSubmitRelatedToTask = (value: TaskRow) => {
    if (props.isCreateTask) {
      setFormValue(
        rafForm,
        propertyOf<TaskRow>("RelatedToUID"),
        value.RelatedToUID
      );
      setFormValue(rafForm, propertyOf<TaskRow>("RelatedTo"), value.RelatedTo);
      setFormValue(
        rafForm,
        propertyOf<TaskRow>("RelatedToType"),
        value.RelatedToType
      );
      closeRelatedToTaskDialog();
    } else {
      let progressDiv = showProgress("#RelatedToDialogContent");
      saveTaskWithReminders(value).then((entityId) => {
        if (isNotNullAndUndefined(entityId)) {
          showSuccessToast("Saved successfully");
          hideProgress(progressDiv);

          if (props.onSave) {
            props.onSave(value);
          }
          setState({ showRelatedToTaskDialogContent: false, isActive: true });
          if (isNotNullAndUndefined(props.taskUID)) {
            refresh(props.taskUID, props.isActive);
          }
          // closeRelatedToTaskDialog();
        } else {
          showWarningToast("Sorry unable to update the changes.");
          hideProgress(progressDiv);
        }
      });
    }
  };

  const refresh = async (uid, isActive) => {
    if (isActive) {
      let progressDiv = showProgress(`#manage_Task_outerDiv_${objGuid}`);
      const [taskRow] = await Promise.all([retrieveTaskWithoutReminders(uid)]);
      if (
        isNotNullAndUndefined(taskRow) &&
        isNotNullAndUndefined(taskRow.UID)
      ) {
        hideProgress(progressDiv);
        setState({
          taskRow,
          isActive,
          isEditTask:
            (isNotNullAndUndefined(taskRow.TaskStatus) &&
              taskRow.TaskStatus === "Completed") ||
              props.isEditTask === false
              ? false
              : true,
          documentSummaryKey: Math.random(),
          activitySummaryKey: Math.random(),
          relatedBPWidgetKey: Math.random(),
          taskDetailsSectionKey: Math.random(),
        });
      } else if (props.isCreateTask === true) {
        hideProgress(progressDiv);
        let taskRow: TaskRow = new TaskRow();
        taskRow.TaskStatus = RAFActionStatus.Planned;
        taskRow.Priority = "Low";
        /*if (isNotNullAndUndefined(props.account)) {
                                                                                    taskRow.RelatedToType = "account";
                                                                                } else if (isNotNullAndUndefined(props.contact)){
                                                                                    taskRow.RelatedToType = "contact";
                                                                                }*/

        // let moduleName = isNotNullAndUndefined(props.moduleName)
        //   ? props.moduleName
        //   : null;

        let relatedToType;

        if (isNotNullAndUndefined(props.relatedToType)) {
          relatedToType = props.relatedToType;
          taskRow.RelatedToType = relatedToType;
        }

        // if (moduleName === RAFEntityName.Dashboard) {
        //   taskRow.RelatedToType = RAFEntityName.Contact;
        // }
        taskRow.RelatedToUID = isNotNullAndUndefined(props.relatedToUID)
          ? props.relatedToUID
          : null;
        taskRow.RelatedTo = isNotNullAndUndefined(props.relatedTo)
          ? props.relatedTo
          : null;
        taskRow.TaskType = isNotNullAndUndefined(props.defaultTaskType)
          ? props.defaultTaskType
          : RAFTaskType.ToDo;

        taskRow.SecondaryRelatedTo = isNotNullAndUndefined(
          props.secondaryRelatedTo
        )
          ? props.secondaryRelatedTo
          : null;
        taskRow.SecondaryRelatedToUID = isNotNullAndUndefined(
          props.secondaryRelatedToUID
        )
          ? props.secondaryRelatedToUID
          : null;
        taskRow.SecondaryRelatedToType = isNotNullAndUndefined(
          props.secondaryRelatedToType
        )
          ? props.secondaryRelatedToType
          : null;
        taskRow.Assignee = msalInstance.currentUserName;
        taskRow.AssigneeUID = msalInstance.currentUserId;
        taskRow.RepeatProcesss = RepeatProcesssItems.Never;
        //taskRow.Reminders = getTaskRemindersRow(null);

        let taskDueDate = null;
        // JSON.parse(
        //   getSessionStorage(ACStorageKey.task_DueDate, true)
        // );
        if (isNotNullAndUndefined(taskDueDate)) {
          taskRow.TaskDate = CalculateDate(taskDueDate["id"]);
        } else {
          if (
            taskRow.TaskType === RAFTaskType.Event ||
            taskRow.TaskType === RAFTaskType.Appointment
          ) {
            taskRow.TaskDate = new Date();
          } else {
            taskRow.DueDate = "NoDate";
            taskRow.TaskDate = Constants.MaxDate;
          }
          //taskRow.TaskDate = moment().add(1, 'days').toDate();
        }

        setState({
          taskRow,
          isActive,
        });
      } else {
        hideProgress(progressDiv);
        setState({ taskRow: null, isActive: false });
      }
    } else {
      setState({ taskRow: null, isActive: false });
    }
  };

  const DeleteTaskClicked = (taskRow: TaskRow) => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: DeleteTask.bind(this, taskRow) },
      showCloseIcon: false,
      title: " Delete Task",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  };

  const DeleteTask = (taskRow: TaskRow) => {
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    // let progressDiv = showProgress(".alert-dialog");
    DeleteRecord(taskRow.UID, moduleName).then((response) => {
      hideProgress(progressDiv);
      if (isNotNullAndUndefined(response)) {
        deleteDialog.hide();
        setState({ isActive: false });
        // if (props.onDelete) {
        //   props.onDelete();
        // } else if (props.onSave) {
        //   props.onSave();
        // }
        let actionList = R.clone(props.actionsList);
        actionList = actionList.filter((x) => x.UID !== taskRow.UID);
        if (props.onActionAdded) {
          props.onActionAdded(actionList);
        }
      }
    });
  };

  const onChangeTaskStatus = async (actionStatus: string) => {
    if (actionStatus === RAFActionStatus.Planned) {
      showReopenTaskAlert(actionStatus);
    } else if (actionStatus === RAFActionStatus.Completed) {
      let hasPermissionToComplete = true;

      if (hasPermissionToComplete) {
        showCompleteTaskAlert(RAFActionStatus.Completed);
      }
    }
  };

  const showCompleteTaskAlert = (taskStatus: string) => {
    updateTaskStatusAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: {
        text: "No",
      },
      closeOnEscape: false,
      content: "Are you sure you want to complete this task?",
      okButton: {
        text: "Yes",
        click: onClickCompleteTask.bind(this, taskStatus),
      },
      showCloseIcon: false,
      title: "Complete Task",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog",
    });
  };

  const showReopenTaskAlert = (taskStatus: string) => {
    updateTaskStatusAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: {
        text: "No",
        click: undoTaskStatusChange.bind(this, taskStatus),
      },
      closeOnEscape: false,
      content: "Are you sure you want to re-open this task?",
      okButton: {
        text: "Yes",
        click: reopenCurrentTask.bind(this, taskStatus),
      },
      showCloseIcon: false,
      title: "Re-open Task",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog",
    });
  };

  const undoTaskStatusChange = () => {
    updateTaskStatusAlertDialog && updateTaskStatusAlertDialog.hide();
    let { taskRow } = state;
    if (isNotNullAndUndefined(taskRow))
      setFormValue(rafForm, "TaskStatus", taskRow.TaskStatus);
  };

  const reopenCurrentTask = (taskStatus: string) => {
    updateTaskStatusAlertDialog && updateTaskStatusAlertDialog.hide();
    setFormValue(rafForm, "TaskStatus", taskStatus);
    setFormValue(rafForm, "CompletedDate", null);
    setFormValue(rafForm, "CompletedBy", null);
    setFormValue(rafForm, "CompletedByUID", null);
    rafForm && rafForm.form.submit();
  };

  const onClickCompleteTask = (taskStatus: string) => {
    updateTaskStatusAlertDialog && updateTaskStatusAlertDialog.hide();

    let taskFormRow: TaskRow = getFormValue(rafForm);
    if (
      isNotNullAndUndefined(taskFormRow) &&
      isNotNullAndUndefined(taskFormRow.UID)
    ) {
      if (isNotNullAndUndefined(rafForm) && rafForm.dirty === true) {
        if (
          isNotNullAndUndefined(taskFormRow) &&
          isNotNullAndUndefined(taskFormRow.UID)
        ) {
          let isMyTeamExist = false;

          if (
            (isNotNullAndUndefined(taskFormRow.AssigneeUID) &&
              taskFormRow.AssigneeUID === msalInstance.currentUserId) ||
            (isNotNullAndUndefined(taskFormRow.AssignTeamUID) &&
              isNotNullAndUndefined(isMyTeamExist))
          ) {
            let progressDiv = showProgress(`#manage_Task_outerDiv_${objGuid}`);
            saveTaskWithReminders(taskFormRow).then((entityId) => {
              hideProgress(progressDiv);
              if (isNotNullAndUndefined(entityId)) {
                if (props.complete) props.complete(taskFormRow, taskStatus); //commented by hari
              }
            });
          } else {
            //ColmpleteTaskAlert(taskStatus);
            showCompleteTaskAlertDialog();
          }
        }
      } else {
        if (
          isNotNullAndUndefined(taskFormRow) &&
          isNotNullAndUndefined(taskFormRow.UID)
        ) {
          let isMyTeamExist = false;

          if (
            (isNotNullAndUndefined(taskFormRow.AssigneeUID) &&
              taskFormRow.AssigneeUID === msalInstance.currentUserId) ||
            (isNotNullAndUndefined(taskFormRow.AssignTeamUID) &&
              isNotNullAndUndefined(isMyTeamExist))
          ) {
            if (props.complete) props.complete(taskFormRow, taskStatus);
          } else {
            showCompleteTaskAlertDialog();
          }
        }
      }
    }
  };

  const completeTask = (taskStatus: string) => {
    let taskFormRow: TaskRow = getFormValue(rafForm);
    if (
      isNotNullAndUndefined(taskFormRow) &&
      isNotNullAndUndefined(taskFormRow.UID)
    ) {
      if (isNotNullAndUndefined(rafForm) && rafForm.dirty === true) {
        let progressDiv = showProgress(`#manage_Task_outerDiv_${objGuid}`);
        saveTaskWithReminders(taskFormRow).then((entityId) => {
          hideProgress(progressDiv);
          if (isNotNullAndUndefined(entityId)) {
            if (props.complete) props.complete(taskFormRow, taskStatus);
          }
        });
      } else {
        if (props.complete) props.complete(taskFormRow, taskStatus);
      }
    }
  };

  const showCompleteTaskAlertDialog = () => {
    setState({ showCompleteTaskAlertDialogContent: true });
  };

  const completeTaskAlertContent = () => {
    if (state.showCompleteTaskAlertDialogContent === true) {
      let taskFormRow: TaskRow = getFormValue(rafForm);
      let assignedTo = isNotNullAndUndefined(taskFormRow.Assignee)
        ? taskFormRow.Assignee
        : taskFormRow.AssignTeam;

      let completeFormInitialValue = {
        Action: "No",
        Message: null,
        Mentions: [],
      };
      return (
        <div className="px-2">
          <RAFForm
            initialValues={completeFormInitialValue}
            submitOnEnterKey={false}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={onSubmitCompleteTask}
          >
            <div className="e-dlg-body-content">
              <div className="row">
                <div className="col-auto">
                  <label>
                    This task is assigned to {assignedTo}. Do you want to
                    complete this task?
                  </label>
                </div>
                <div className="col">
                  <RAFRadioButtonList
                    field="Action"
                    label="Complete ?"
                    showLabel={false}
                    labelClassName="col-auto"
                    inputFieldClassName="col radio-btn-m-0"
                    formGroupClassName="mb-0"
                    uitype="customButton"
                    radioBtnClassName="col-auto"
                  >
                    <RAFChoiceOption
                      label="Yes"
                      value={"Yes"}
                    ></RAFChoiceOption>
                    <RAFChoiceOption label="No" value={"No"}></RAFChoiceOption>
                  </RAFRadioButtonList>
                </div>
              </div>
            </div>
            <div className="e-dlg-footerContent ">
              <ButtonComponent className="me-2" cssClass="e-flat e-info">
                OK
              </ButtonComponent>
              <ButtonComponent
                type="button"
                cssClass="e-flat"
                onClick={() => showCompleteTaskAlertDialogClose()}
              >
                Cancel
              </ButtonComponent>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const onSubmitCompleteTask = (value) => {
    let taskFormRow: TaskRow = getFormValue(rafForm);
    if (
      isNotNullAndUndefined(value) &&
      value.Action === "Yes" &&
      isNotNullAndUndefined(taskFormRow)
    ) {
      completeTask(RAFActionStatus.Completed);
    } else {
      showCompleteTaskAlertDialogClose();
    }
  };

  const showCompleteTaskAlertDialogClose = () => {
    setState({ showCompleteTaskAlertDialogContent: false });
  };

  const clearTaskRelatedTo = () => {
    rafLookupSelectorForm.form.mutators.setValue("RelatedTo", null);
    rafLookupSelectorForm.form.mutators.setValue("RelatedToUID", null);
    rafLookupSelectorForm.form.mutators.setValue("RelatedToType", null);
  };

  //showRelatedToTaskDialogContent start
  const openRelatedToTaskDialog = () => {
    setState({ showRelatedToTaskDialogContent: true });
  };
  const closeRelatedToTaskDialog = () => {
    setState({ showRelatedToTaskDialogContent: false });
  };

  const relatedToTaskDialogContent = () => {
    if (state.showRelatedToTaskDialogContent === true) {
      const taskRow = isNotNullAndUndefined(rafForm)
        ? getFormValue(rafForm)
        : state.taskRow;
      return (
        <div className="px-2">
          <RAFForm
            className="h-100"
            type={TaskRow}
            initialValues={taskRow}
            formRef={(g) => {
              enableSubmitButton(g);
              return (rafLookupSelectorForm = g);
            }}
            layout={RAFLayout.TwoColumnLayout}
            //primaryKey={taskRow.UID}
            onSubmit={onSubmitRelatedToTask}
            submitOnEnterKey={false}
          >
            <div className="e-dlg-body-content">
              <RAFRelatedLookupSelector
                field="RelatedTo"
                relatedField="RelatedToType"
                moduleName={moduleName}
              //enableFloatingPopup={false}
              ></RAFRelatedLookupSelector>
            </div>
            <div className="e-dlg-footerContent justify-content-start">
              <RAFButtonComponent
                action={RAFButtonConstant.Clear}
                onClick={() => clearTaskRelatedTo()}
                idString={taskRow.UID}
                showIcon={false}
              />
              <RAFButtonComponent
                action={RAFButtonConstant.Cancel}
                onClick={() => closeRelatedToTaskDialog()}
                idString={taskRow.UID}
                showIcon={false}
                className="ms-auto me-2"
              />
              <RAFPermissionRender
                permissionName={
                  PermissionConstants.TaskManage
                }
              >
                <RAFButtonComponent
                  action={RAFButtonConstant.Save}
                  type="submit"
                  isPrimary
                  idString={taskRow.UID}
                  showIcon={false}
                />
              </RAFPermissionRender>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  //showRelatedToTaskDialogContent end

  const onCloseDialogClick = () => {
    setTimeout(() => {
      if (props.onClose) {
        setState({
          taskRow: null,
        });
        props.onClose();
      }
    }, 100);
  };

  const showAddToFocusListDialog = () => {
    setState({ showAddToFocusListDialog: true });
  };

  const onChangeTaskDate = (value) => {
    let taskDueDate = getTaskDueDate(null, value);
    setFormValue(rafForm, "DueDate", taskDueDate);
  };

  const RelatedToClick = async (objectid, contextModuleName, item) => {
    let entityName = getURLNameByModuleName(contextModuleName);
    if (
      isNotNullAndUndefined(contextModuleName) &&
      isNotNullAndUndefined(objectid)
    ) {
      let selectedEntity = await getEntityByName(contextModuleName);
      if (selectedEntity.EntityType === RAFEntityType.DataList) {
        if (
          entityName === RAFEntityName.Document ||
          entityName === RAFEntityName.ContentLibrary
        ) {
        } else {
          props.navigate("/" + entityName + "/view/" + objectid);
        }
      } else {
        props.navigate("/RAF/View/" + contextModuleName + "/" + objectid);
      }
    }
  };

  const renderCompletedTaskContent = (taskRow: TaskRow) => {
    const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
      taskRow.TaskDate,
      taskRow.TaskType,
      true
    );
    const taskDisplayDate = taskDisplayDateAndBadge.taskDueDate;

    return (
      <div className={"row gx-0 gy-3"}>
        <div className="col-12">
          <div className="row gy-2">
            <div className="col-12">
              <div className="gx-2 gy-1 row">
                <div className="col-auto">
                  <RAFDetailFieldCustom
                    value={
                      isNotNullAndUndefined(taskRow.TaskType)
                        ? taskRow.TaskType
                        : "Not set"
                    }
                    displayValue={taskRow.TaskType}
                    title="TaskType"
                    moduleName={RAFEntityName.Task}
                    isColorOption
                    field="TaskType"
                    mode="opacityColorView"
                    showLabel={false}
                    valueClassName="raf_badge raf_sm"
                  />
                </div>
                <div className="col-12 subtitle_2">
                  <span className="subtitle_2">{taskRow.Title}</span>
                </div>
              </div>
            </div>
            {isNotNullAndUndefined(taskRow.Description) ? (
              <div className="col-12">
                <CustomCardWidget cardContentClassName="p-2">
                  {taskRow.Description}
                </CustomCardWidget>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-12">
          <RAFDetailFieldCustom
            value={
              isNotNullAndUndefined(taskRow.TaskStatus)
                ? taskRow.TaskStatus
                : "Not set"
            }
            displayValue={taskRow.TaskStatus}
            title="TaskStatus"
            moduleName={RAFEntityName.Task}
            isColorOption
            field="TaskStatus"
            mode="textOnly"
            showLabel={true}
            // valueClassName="body_3_dark medium"
            // labelClassName="body_3_light"
            // labelDivClassName="body_3_light col-auto"
            // valueDivClassName="body_3_dark col-auto"
            rowClassName="gx-0 gy-1"
          />
        </div>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-12">
          <RAFDetailFieldCustom
            value={taskDisplayDate}
            title="Due date"
            colClassName="col-12"
            rowClassName="gy-1"
          //fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATETIME }}
          />
        </div>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-12">
          <RAFDetailFieldCustom
            value={taskRow.Assignee}
            title="Assigned to"
            colClassName="col-12"
            rowClassName="gy-1"
            customValue={
              <div>
                <div className="row gx-2">
                  <div className="col-auto">Profile Photo</div>
                  <div className="col">
                    <div className="details-value">{taskRow.Assignee}</div>
                  </div>
                </div>
              </div>
            }
          />
        </div>

        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <RAFFieldLabel
              field="RelatedTo"
              label="Related To"
              labelClassName="col-12"
            ></RAFFieldLabel>
            <div className="col-12">{renderRelatedToContent(taskRow)}</div>
          </div>
        </div>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-md-12">
          <RAFTaskTagInput<TaskRow>
            field="TagsListJson"
            label="Tags"
            showLabel={false}
            // showLabel={true}
            placeholder="Type and press enter to add a tag"
            showAddBtn
            disabled
          />
        </div>
      </div>
    );
  };

  const renderEditTaskContent = (taskRow, taskTypeQueryAttribute) => {
    return (
      <div className="row gx-2 gx-md-3 gx-xl-3 overflow-hidden gy-3 form-group-margin-0 mb-3">
        <div className="col-md-12">
          <RAFDropdownCC<TaskRow>
            field="TaskType"
            label="Type"
            placeholder="Select Type"
            isColorOption
            // mode="discView"
            uitype="colorpicker"
            createOptionMode={"Footer"}
            moduleName={moduleName}
            required
            displayDropdownType={"customDropdownBtnForMinCount"}
            //onChanged={onChangeTaskType}
            allowAdd
            queryAttribute={taskTypeQueryAttribute}
          />
        </div>
        <div className="col-md-12 titleFieldOuter">
          <RAFTextBox<TaskRow>
            field="Title"
            label="Title"
            showLabel
            required
          // formGroupClassName="inline-Editable"
          // labelClassName="col-3"
          // inputFieldClassName="col-9"
          ></RAFTextBox>
        </div>
        <div className="col-md-12">
          <RAFTextArea<TaskRow>
            field="Description"
            showLabel
            label="Description"
            placeholder="Description"
            // rows={2}
            formGroupClassName="m-0"
          ></RAFTextArea>
        </div>
        <ConditionNotIncludes
          when={propertyOf<TaskRow>("TaskType")}
          isNot={[RAFTaskType.Event, RAFTaskType.Appointment]}
        >
          <div className="col-md-12 cssradio-mb-0">
            <RAFDatePickerCustom
              field="TaskDate"
              secondaryField="DueDate"
              label="Due date"
              displayStyle="splitButton"
              showLabel
              // formGroupClassName="inline-Editable"
              // showLabel={true}
              // onChanged={(days, date) =>
              //   onModifyQuickDateSelected(days, date)
              // }
              dropdownDateOptions={[
                { id: 0, text: "ASAP", value: "ASAP" },
                // { id: 1, text: 'Today', value: '0' },
                { id: 2, text: "1D", value: "1" },
                { id: 3, text: "3D", value: "3" },
                { id: 4, text: "7D", value: "7" },
                { id: 5, text: "15D", value: "15" },
                { id: 6, text: "1M", value: "30" },
                { id: 7, text: "2M", value: "60" },
              ]}
              allowMaxValue
              hideMinAndMaxValue
              showClearTextBtn
              onChanged={(value) => onChangeTaskDate(value)}
            // labelClassName="col-3"
            // inputFieldClassName="col-9"
            />
          </div>
        </ConditionNotIncludes>
        <ConditionIncludes
          when={propertyOf<TaskRow>("TaskType")}
          is={[RAFTaskType.Event, RAFTaskType.Appointment]}
        >
          <div className="col-md-12">
            <RAFStartDateEndDatePicker
              field={propertyOf<TaskRow>("TaskDate")}
              endDateField={propertyOf<TaskRow>("EndDate")}
              label="Start Date"
              required
              interval={5}
              formGroupClassName="mb-0"
            />
          </div>
        </ConditionIncludes>
        {(taskRow && state.isEditTask === true) ||
          props.isCreateTask === true ? (
          <div className="col-md-12">
            <RAFLookUpMUI
              field="Assignee"
              label="Assigned To"
              url="User/Lookup"
              // textField="EmployeeName"
              //valueField="UID"
              placeholder="Select Assignee"
              type={UserRow}
            />
          </div>
        ) : null}
        {taskRow && taskRow.TaskStatus === RAFActionStatus.Completed ? (
          <div className="col-md-12">
            <RAFDropdownCC<TaskRow>
              field="TaskStatus"
              label="Status"
              placeholder="TaskStatus"
              isColorOption
              // mode="discView"
              uitype="colorpicker"
              createOptionMode={"Footer"}
              moduleName={moduleName}
              allowAdd
            />
          </div>
        ) : (
          ""
        )}
        {contextModuleName === RAFEntityName.Dashboard && (
          <div className="col-md-12">
            <div className="row">
              <RAFFieldLabel
                field="RelatedTo"
                label="Related To"
                labelClassName="col-12"
              ></RAFFieldLabel>
              <div className="col-12">{renderRelatedToContent(taskRow)}</div>
            </div>
          </div>
        )}
        <div className="col-md-12">
          <RAFAutoCompleteMUITagInput<TaskRow>
            field="TagsListJson"
            label="Tags"
            showLabel={true}
            // showLabel={true}
            placeholder="Type and press enter to add a tag"
            showAddBtn
            rowClassName="gy-0 gx-2"
            moduleName={moduleName}
          />
        </div>
        <Condition when="IsApprovalTask" is="No">
          <div className="col-md-12">
            <ButtonComponent
              iconCss="fas fa-check"
              cssClass="btn-muted btn-active-success"
            >
              Mark Complete
            </ButtonComponent>
          </div>
        </Condition>
        <Condition when="IsApprovalTask" is="Yes">
          <div className="col-md-12 d-flex mb-3">
            <ButtonComponent
              iconCss="far fa-check-circle"
              cssClass="btn-muted btn-active-primary w-100 m-2 ms-0"
            >
              Approve
            </ButtonComponent>
            <ButtonComponent
              iconCss="far fa-times-circle"
              cssClass="btn-muted btn-active-danger w-100 m-2"
            >
              Reject
            </ButtonComponent>
            <ButtonComponent cssClass="btn-muted btn-active-warning w-100 m-2 me-0">
              <span className="position-relative">
                <i className="fas fa-redo e-btn-icon e-icon-left"></i>
                <i
                  className="fas fa-check position-absolute"
                  style={{
                    top: "calc(50% - 2px)",
                    left: "4px",
                    fontSize: "6px",
                  }}
                ></i>
              </span>
              Request Changes
            </ButtonComponent>
          </div>
        </Condition>
      </div>
    );
  };

  const renderRelatedToContent = (taskRow) => {
    return (
      <Fragment>
        <Field name={"RelatedToUID"}>
          {({ input }) => {
            const relatedToUID = input.value;
            return (
              <Field name={"RelatedToType"}>
                {({ input }) => {
                  const relatedToType = input.value;
                  return (
                    <Field name={"RelatedTo"}>
                      {({ input }) => {
                        const relatedTo = input.value;
                        return (
                          <div
                            className="e-card pointer"
                            key={state.relatedToContentKey}
                          >
                            <div className="e-card-content p-3 py-2">
                              {isNotNullAndUndefined(relatedToType) &&
                                isNotNullAndUndefined(relatedToUID) &&
                                !IsNullOrWhiteSpace(relatedToType) &&
                                !IsNullOrWhiteSpace(relatedToUID) ? (
                                <div className="d-flex align-items-center justify-content-between">
                                  <div
                                    onClick={() =>
                                      RelatedToClick(
                                        relatedToUID,
                                        relatedToType,
                                        taskRow
                                      )
                                    }
                                  >
                                    <RAFRelatedToWithIcon
                                      relatedToType={relatedToType}
                                      relatedTo={relatedTo}
                                      {...(props.isCreateTask !== true
                                        ? {
                                          showTextAsLink: true,
                                        }
                                        : {})}
                                    />
                                  </div>

                                  {(state.isEditTask === true ||
                                    props.isCreateTask === true) &&
                                    contextModuleName ===
                                    RAFEntityName.Dashboard &&
                                    taskRow.RelatedToType !==
                                    "business_process" ? (
                                    isNotNullAndUndefined(relatedToUID) ? (
                                      <ButtonComponent
                                        type="button"
                                        cssClass="custom-button-sm primary-custom-button"
                                        content={
                                          RAFButtonConstant.Edit.DisplayName
                                        }
                                        iconCss={RAFButtonConstant.Edit.IconCss}
                                        id={`btn_${RAFButtonConstant.Edit.Id}_relatedSection`}
                                        onClick={() =>
                                          openRelatedToTaskDialog()
                                        }
                                      ></ButtonComponent>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div className="d-flex align-items-center justify-content-center">
                                  {(state.isEditTask === true ||
                                    props.isCreateTask === true) &&
                                    contextModuleName ===
                                    RAFEntityName.Dashboard &&
                                    taskRow.RelatedToType !==
                                    "business_process" ? (
                                    isNotNullAndUndefined(
                                      taskRow.RelatedToUID
                                    ) ? (
                                      // <ButtonComponent type="button" cssClass="link-button" content={RAFBtnContentString.Edit} iconCss={RAFBtnIconCss.Edit} id={`btn_${RAFBtnIdString.Edit}_relatedSection`} onClick={() => manageTaskRelatedSectionClicked()}></ButtonComponent>
                                      ""
                                    ) : (
                                      <ButtonComponent
                                        type="button"
                                        cssClass="link-button"
                                        isPrimary
                                        content={"Link Record"}
                                        iconCss={RAFButtonConstant.Add.IconCss}
                                        id={`btn_${RAFButtonConstant.Add.Id}_relatedSection`}
                                        onClick={() =>
                                          openRelatedToTaskDialog()
                                        }
                                      ></ButtonComponent>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  );
                }}
              </Field>
            );
          }}
        </Field>
        {state.showRelatedToTaskDialogContent && (
          <DialogComponent
            header="Link this task to"
            showCloseIcon={true}
            visible={state.showRelatedToTaskDialogContent}
            cssClass="rightDialog rightDialog-sm full-height createEditForm form-center-dialog"
            id="RelatedToDialogContent"
            content={relatedToTaskDialogContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeRelatedToTaskDialog.bind(this)}
            animationSettings={animationSettings}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          ></DialogComponent>
        )}
      </Fragment>
    );
  };

  const renderTabContent = (taskRow, taskTypeQueryAttribute) => {
    return renderFormContent(taskRow, taskTypeQueryAttribute);
  };

  const renderFormContent = (taskRow, taskTypeQueryAttribute) => {
    return (
      <Fragment>
        <RAFForm
          className="h-100"
          type={TaskRow}
          initialValues={taskRow}
          formRef={(g) => {
            enableSubmitButton(g);
            return (rafForm = g);
          }}
          layout={RAFLayout.TwoColumnLayout}
          //primaryKey={taskRow.UID}
          onSubmit={onSubmitTask}
          submitOnEnterKey={false}
        >
          <div>
            {state.isEditTask === true || props.isCreateTask === true
              ? renderEditTaskContent(taskRow, taskTypeQueryAttribute)
              : renderCompletedTaskContent(taskRow)}
          </div>
        </RAFForm>
      </Fragment>
    );
  };

  const onClickTaskSaveBtn = () => {
    if (isNotNullAndUndefined(rafForm)) {
      rafForm.form.submit();
    }
  };

  // const updateBPStatusAndRefreshTask = () => {
  //   setState({ isActive: false });
  //   refresh(props.taskUID, true);
  // };

  const onEditClicked = () => {
    setState({ isEditTask: true });
  };

  if (state.isActive === true) {
    return (
      <RAFEntityProvider moduleName={RAFModuleName.Task}>
        <RAFAttributeRelatedListProvider
          moduleName={RAFModuleName.Task}
          progressDivId={`#manage_Task_outerDiv_${objGuid}`}
        >
          <RAFAttributesContext.Consumer>
            {({ queryAttributes }) => {
              let { isLoading, taskRow } = state;
              let isCreateTask = props.isCreateTask;

              if (isNotNullAndUndefined(taskRow) && isLoading === false) {
                let queryAttributeClone: QueryAttributeJM[] =
                  R.clone(queryAttributes);
                let taskTypeQueryAttribute =
                  queryAttributeClone &&
                  queryAttributeClone.find(
                    (x) => x.AttributeName === "task_type"
                  );
                // if (
                //   taskTypeQueryAttribute &&
                //   isNotEmptyArray(taskTypeQueryAttribute.ValueJson)
                // ) {
                //   if (taskRow.RelatedToType === "incident") {
                //     taskTypeQueryAttribute.ValueJson =
                //       taskTypeQueryAttribute.ValueJson.filter(
                //         (x) =>
                //           x.DisplayName !== RAFTaskType.Appointment &&
                //           x.DisplayName !== RAFTaskType.ToDo
                //       );
                //   } else {
                //     taskTypeQueryAttribute.ValueJson =
                //       taskTypeQueryAttribute.ValueJson.filter(
                //         (x) =>
                //           x.DisplayName === RAFTaskType.Appointment ||
                //           x.DisplayName === RAFTaskType.ToDo
                //       );
                //   }
                // }

                return (
                  <div
                    className="h-100 bg-white"
                    id={`manage_Task_outerDiv_${objGuid}`}
                    key={`manage_Task_outerDiv_${objGuid}_${state.taskDetailsSectionKey}`}
                  >
                    <div
                      className={
                        props.isDetailsMode
                          ? "h-100 d-flex flex-column bg-white"
                          : "dlg-new-style"
                      }
                    >
                      {props.isDetailsMode !== true &&
                        props.hideHeader !== true && (
                          <div
                            className={
                              props.isDetailsMode
                                ? "sectionHeader border-bottom d-flex align-items-center justify-content-between"
                                : "e-dlg-headerContent h-auto tab_fixedHeader_scrollableContent_dialog_header"
                            }
                            id="manage_Task_outerDiv_dialog-header"
                          >
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <div
                                className={
                                  props.isDetailsMode
                                    ? "header-text"
                                    : "e-dlg-header"
                                }
                                id="_title"
                              >
                                {isCreateTask === true ? (
                                  "Add Action"
                                ) : (
                                  <div className="d-flex flex-column align-items-start">
                                    <div className="header_5 mb-1">
                                      <span className="ecllipseFirstLine header_5 semi_bold">
                                        {" "}
                                        {state.isEditTask === false
                                          ? "Details"
                                          : "Modify Action"}
                                      </span>
                                    </div>
                                    <RAFRecordInfo
                                      createdDate={taskRow.CreatedDate}
                                      createdBy={taskRow.CreatedBy}
                                      modifiedBy={taskRow.ModifiedBy}
                                      modifiedDate={taskRow.ModifiedDate}
                                      lastActivityDate={
                                        taskRow.LastActivityDate
                                      }
                                      {...(window.innerWidth > 768 && {
                                        content: `<span class='d-flex flex-nowrap body_1_light'>Updated by <span class='ecllipseFirstLine word-break-all text-inherit text-wrap px-1 body_1_light'> ${taskRow.ModifiedBy}</span></span>`,
                                      })}
                                      spanContentClassName="body_1 text-decoration-underline"
                                      hideClockIcon
                                    ></RAFRecordInfo>
                                  </div>
                                )}
                              </div>
                              {props.isDetailsMode ? (
                                <ButtonComponent
                                  type="button"
                                  cssClass="e-small link-button grey-link-button unset-custom-button-md"
                                  iconCss="fas fa-xmark"
                                  onClick={() => {
                                    onCloseDialogClick();
                                  }}
                                ></ButtonComponent>
                              ) : (
                                <RAFButtonComponent
                                  displayMode="DialogCloseBtn"
                                  onClick={() => onCloseDialogClick()}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      <div
                        key={`manage_Task_outerDiv_dialog-body_${objGuid}_${state.taskDetailsSectionKey}`}
                        className={
                          props.isDetailsMode
                            ? "p-3 h-100 overflow-auto customScrollBar position-relative"
                            : "e-dlg-body-content taskUpdateForm position-relative pb-3 mb-3 px-lg-4"
                        }
                      >
                        {renderTabContent(taskRow, taskTypeQueryAttribute)}
                      </div>
                      {state.isEditTask === false && isCreateTask !== true ? (
                        <div
                          className={
                            props.isDetailsMode
                              ? "p-2 border-top"
                              : "e-dlg-footerContent"
                          }
                        >
                          <div className="w-100">
                            <div className="row gx-2 align-items-center">
                              {(taskRow.TaskStatus !==
                                RAFStatusNameWithColor.Completed.Value ||
                                taskRow.TaskStatus !==
                                RAFStatusNameWithColor.Completed
                                  .DisplayName) && (
                                  <div className="col-auto">
                                    <RAFPermissionRender
                                      permissionName={
                                        PermissionConstants.TaskDelete
                                      }
                                    >
                                      <RAFButtonComponent
                                        action={RAFButtonConstant.Delete}
                                        iconBtn
                                        className="btn_state_danger transparent"
                                        onClick={() => DeleteTaskClicked(taskRow)}
                                      ></RAFButtonComponent>
                                    </RAFPermissionRender>
                                  </div>
                                )}
                              <div className="col-auto ms-auto">
                                <RAFButtonComponent
                                  action={RAFButtonConstant.Edit}
                                  onClick={() => onEditClicked()}
                                  idString={taskRow.UID}
                                  showIcon={false}
                                  className="btn_brand_primary semi_dark"
                                />
                              </div>
                              {taskRow &&
                                taskRow.TaskStatus === RAFActionStatus.Planned &&
                                (taskRow.TaskType === RAFTaskType.ToDo ||
                                  taskRow.TaskType === RAFTaskType.Approval ||
                                  taskRow.TaskType === RAFTaskType.Appointment ||
                                  taskRow.TaskType === RAFTaskType.Action ||
                                  taskRow.TaskType === RAFTaskType.Event)
                                ? (hasPermission(
                                  permissionValue,
                                  PermissionConstants.TaskManage
                                ) ||
                                  taskRow.AssigneeUID ===
                                  msalInstance.currentUserId) && (
                                  <div className="col-auto">
                                    <ButtonComponent
                                      type="button"
                                      className="btn_state_success semi_dark"
                                      onClick={() =>
                                        onChangeTaskStatus(
                                          RAFActionStatus.Completed
                                        )
                                      }
                                      content={
                                        RAFButtonConstant.MarkAsDone
                                          .DisplayName
                                      }
                                      // iconCss={RAFBtnIconCss.Save}
                                      id={`btn_${RAFButtonConstant.MarkAsDone.Id}_${taskRow.TitleUID}`}
                                    />
                                  </div>
                                )
                                : null}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={
                            props.isDetailsMode
                              ? "p-2 border-top d-flex align-items-center justify-content-end"
                              : "e-dlg-footerContent"
                          }
                        >
                          <div className="w-100">
                            <div className="row gx-2">
                              {taskRow.TaskStatus !==
                                RAFStatusNameWithColor.Completed.DisplayName &&
                                isCreateTask !== true && (
                                  <RAFPermissionRender
                                    permissionName={
                                      PermissionConstants.TaskDelete
                                    }
                                  >
                                    <div className="col-auto">
                                      <RAFButtonComponent
                                        action={RAFButtonConstant.Delete}
                                        iconBtn
                                        className="btn_state_danger transparent"
                                        onClick={() =>
                                          DeleteTaskClicked(taskRow)
                                        }
                                      ></RAFButtonComponent>
                                    </div>
                                  </RAFPermissionRender>
                                )}
                              <RAFPermissionRender
                                permissionName={
                                  PermissionConstants.TaskManage
                                }
                              >
                                <div className="col-auto ms-auto">
                                  <ButtonComponent
                                    type="button"
                                    className="btn_brand_primary semi_dark"
                                    onClick={onClickTaskSaveBtn}
                                    content={RAFButtonConstant.Save.DisplayName}
                                    // iconCss={RAFBtnIconCss.Save}
                                    id={`btn_${RAFButtonConstant.Save.Id}_${taskRow.TitleUID}`}
                                  ></ButtonComponent>
                                </div>
                              </RAFPermissionRender>
                              {taskRow.TaskStatus === RAFActionStatus.Planned &&
                                isCreateTask !== true
                                ? (hasPermission(
                                  permissionValue,
                                  PermissionConstants.TaskManage
                                ) ||
                                  taskRow.AssigneeUID ===
                                  msalInstance.currentUserId) && (
                                  <div className="col-auto">
                                    <ButtonComponent
                                      type="button"
                                      className="btn_state_success semi_dark"
                                      onClick={() =>
                                        onChangeTaskStatus(
                                          RAFActionStatus.Completed
                                        )
                                      }
                                      content={
                                        RAFButtonConstant.MarkAsDone
                                          .DisplayName
                                      }
                                      // iconCss={RAFBtnIconCss.Save}
                                      id={`btn_${RAFButtonConstant.MarkAsDone.Id}_${taskRow.TitleUID}`}
                                    />
                                  </div>
                                )
                                : null}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      {state.showCompleteTaskAlertDialogContent === true ? (
                        <DialogComponent
                          id={`completeTaskAlertDialogContent_${moduleName}`}
                          header="Complete Task"
                          showCloseIcon
                          visible={state.showCompleteTaskAlertDialogContent}
                          cssClass="centerDialog-sm createEditForm full-height form-center-dialog"
                          content={completeTaskAlertContent.bind(this)}
                          isModal
                          target="body"
                          closeOnEscape={false}
                          close={showCompleteTaskAlertDialogClose.bind(this)}
                          zIndex={1200}
                          open={PreventFocusOnDialogOpen}
                        ></DialogComponent>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              } else {
                return <div className="mb-1" id="manage_Task_outerDiv"></div>;
              }
            }}
          </RAFAttributesContext.Consumer>
        </RAFAttributeRelatedListProvider>
      </RAFEntityProvider>
    );
  } else {
    return <div className="mb-1" id={`manage_Task_outerDiv_${objGuid}`}></div>;
  }
}

export default React.memo(withRouter(ManageAction));
